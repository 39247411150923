import Phaser from "phaser";

export class DjBroad extends Phaser.Scene {
  constructor() {
    super("djbroad");
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    this.cameras.main.setBackgroundColor("#fff");

    this.w = this.game.config.width;
    this.h = this.game.config.height;

    // set height of state
    this.state = this.add.sprite(this.w / 2, (this.h * 0.7) / 2, "state2");
    this.state.setDisplaySize(this.h * 1.024 * 0.7, this.h * 0.7);

    this.cameras.main.setBackgroundColor("#000000");

    // bass horns percusssion piaono sfx vox

    // play loop
    // this.bass.play({ loop: true });
    // this.horns.play({ loop: true });
    // this.percussion.play({ loop: true });
    // this.piano.play({ loop: true });
    // this.sfx.play({ loop: true });
    // this.vox.play({ loop: true });
  }

  stateUpdate() {
    this.tweens.add({
      targets: this.state,
      displayHeight: this.h * 0.85,
      displayWidth: this.h * 0.85,
      y: this.h * 0.45,
      duration: 1000,
      alpha: 0,
      onComplete: () => {
        // this.scene.start("djbroad");
      },
    });
  }
}
