import {
  MotionWrap,
  Wrap,
  Input,
  AgeRange,
  Gender,
  Terms,
  Button,
} from "../helper";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SvgComponent, { InvertSvg } from "../transition";
import { useSuperFan } from "../../context";
import { useLocation } from "react-router-dom";

const Form = ({ routeTo = "/", phaserRef }) => {
  const { trackBtn, submit } = useSuperFan();

  const [info, setInfo] = React.useState({
    name: "",
    countryCode: "+65",
    mobile: "",
    ageGroup: "",
    gender: "",
    term: false,
  });

  const nav = useNavigate();
  const [triggerSvg, setTriggerSvg] = React.useState(true);
  const location = useLocation();

  useMemo(() => {
    let info = localStorage.getItem("info");
    if (info === "true") {
      // reload page to "/"
      window.open("/", "_self");
    }
  }, [location.pathname]);

  return (
    <Wrap
      background={"url(/2d/bg.webp)"}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
      overscrollBehavior = {"none"}
    >
      <div
        style={{
          width: "100vw",
          position: "absolute",
          maxWidth: "700px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "4svh",
          padding: `0 1rem`,
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={"/2d/logo3.webp"}
            alt="navbutton"
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100vw",
          height: "100svh",
          maxWidth: "700px",
          overflow: "hidden",
          position: "absolute",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "100",
          pointerEvents: "none",
        }}
      >
        <div
          className="svgComponent"
          style={{
            width: "100svh",
            height: "100svh",
          }}
        >
          <InvertSvg trigger={triggerSvg} />
        </div>
      </div>

      <MotionWrap
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        width="95%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="0 2rem"
        delay={0}
      >
        {/* before */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0) 3%, #000000ec 100%)",
            height: "70%",
            zIndex: "-1",
            bottom: "-20%",
          }}
        />
        {/* after */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0,0,0,0) 3%, #000000ec 100%)",
            height: "70%",
            zIndex: "-1",
            top: "-20%",
          }}
        />

        {/* rest */}
        <h2
          style={{
            color: "#A69C92",
            textTransform: "uppercase",
            fontSize: "0.8rem",
          }}
        >
          Enter your details
        </h2>
        <div className="block" style={{ height: "2svh" }} />
        <Input
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase();
            setInfo({ ...info, name: e.target.value });
          }}
          placeholder="Name"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Input
            onChange={(e) => {
              setInfo({ ...info, countryCode: e.target.value });
            }}
            placeholder={info.countryCode}
            size="20%"
            type="number"
          />
          <Input
            onChange={(e) => {
              setInfo({ ...info, mobile: e.target.value });
            }}
            size="75%"
            placeholder="Mobile"
            type="tel"
          />
        </div>

        <AgeRange
          onChange={(e) => {
            setInfo({ ...info, ageGroup: e.target.value });
          }}
        />
        <Gender
          onChange={(e) => {
            setInfo({ ...info, gender: e.target.value });
          }}
        />

        <Terms setInfo={setInfo} term={info.term} />
        <div className="block" style={{ height: "2svh" }} />

        <Button
          param={"ENTER"}
          id={"enterForm"}
          onClick={() => {
            if (info.name === "") {
              alert("Please enter your name");
              return;
            }
            if (info.mobile === "") {
              alert("Please enter your mobile number");
              return;
            }

            if (info.mobile.length < 6 || info.mobile.length > 10) {
              alert("Please enter a valid mobile number");
              return;
            }

            if (info.ageGroup === "") {
              alert("Please select your age group");
              return;
            }

            if (info.gender === "") {
              alert("Please select your gender");
              return;
            }

            if (info.term === false) {
              alert("Please agree to the terms and conditions");
              return;
            }

            trackBtn("enterForm");
            localStorage.setItem("info", "true");
            submit(info);
            nav(routeTo);
          }}
        />
      </MotionWrap>
    </Wrap>
  );
};

export default Form;
