import Phaser from "phaser";
import { Bootstrap } from "./bootstrap.js";
import { DjBroad } from "./djbroad.js";
import { DjBroadPre } from "./djbroadpre.js";
import { Result } from "./result.js";
import React, { useEffect, useMemo } from "react";
import { Video } from "./video.js";
import { Door } from "./door.js";
import { useLocation } from "react-router-dom";
import { Video2 } from "./video2.js";
const config = {
  type: Phaser.AUTO,
  parent: "phaser-container",
  backgroundColor: "transparent",
  transparent: true,
  width:
    window.innerWidth > window.innerHeight
      ? 700 * window.devicePixelRatio
      : window.innerWidth * window.devicePixelRatio,
  height: window.innerHeight * window.devicePixelRatio,
  scale: {
    mode: Phaser.Scale.ScaleModes.FIT,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0 },
      debug: true,
    },
  },

  scene: [Bootstrap, DjBroad, DjBroadPre, Result, Video, Door, Video2],
};

const IoPhaser = React.forwardRef((props, ref) => {
  useEffect(() => {
    const phaserGame = new Phaser.Game(config);
    ref.current = phaserGame;
    return () => phaserGame.destroy(true);
  }, []);

  const location = useLocation();

  const show = useMemo(() => {
    switch (location.pathname) {
      case "/center-stage":
        return true;
      case "/center-stage/":
        return true;
      case "/center-stage/broad":
        return true;
      case "/center-stage/broad/":
        return true;
      case "/":
        return true;
      case "/center-stage/result":
        return true;
      case "/center-stage/result/":
        return true;
      case "/fashion-walkway":
        return true;
      case "/welcome":
        return true;
      case "/elevator":
        return true;
      case "/form":
        return false;
      case "/navpage":
        return false;
      default:
        return false;
    }
  }, [location.pathname]);

  return (
    <div
      id="phaser-container"
      style={{
        position: "absolute",
        top: "0",
        left: "50%",
        zIndex: "-10",
        width: "100vw",
        height: "100svh",
        maxWidth: "700px",
        overflow: "hidden",
        pointerEvents: "null",
        transform: "translateX(-50%)",
        opacity: show ? 1 : 0,
      }}
    ></div>
  );
});

export default IoPhaser;
