// responsive handerler
import React from "react";
import { motion } from "framer-motion";

export const Wrap = (props) => {
  const { children, ...rest } = props;
  return (
    <section
      style={{
        width: "100vw",
        height: "100svh",
        ...rest,
        maxWidth: "700px",
        position: "relative",
        margin: "auto",
      }}
    >
      {children}
    </section>
  );
};

export const MotionWrap = (props) => {
  const { children, delay = 0, ...rest } = props;
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1,
          delay: delay,
        },
      }}
      style={{
        ...rest,
      }}
    >
      {children}
    </motion.section>
  );
};

export const Button = ({ id, param, onClick, color = "" }) => {
  const [onHover, setOnHover] = React.useState(false);
  return (
    <div
      style={{
        display: "block",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
      id={id}
    >
      <motion.div
        onClick={() => {
          setOnHover(true);
          onClick();
        }}
        style={{
          width: 2 * 5.8 + "rem",
          height: "2rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "",
          margin: "auto",
        }}
        animate={
          !onHover
            ? {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 10%, rgba(23,23,23,1) 100%)",
              }
            : {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 100%, rgba(23,23,23,1) 100%)",
                transition: {
                  duration: 0.5,
                },
              }
        }
      >
        <div
          style={{
            width: "110%",
            height: "130%",
            border: "1px solid #4c4c4c",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          style={{
            width: "105%",
            height: "160%",
            border: "1px solid #4c4c4c",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />{" "}
        <h3
          style={{
            fontSize: "0.6rem",
            color: color,
          }}
        >
          {param}
        </h3>
      </motion.div>
    </div>
  );
};

export const Button_s = ({ param, onClick }) => {
  const [onHover, setOnHover] = React.useState(false);
  return (
    <div
      style={{
        display: "block",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <motion.div
        onClick={() => {
          setOnHover(true);
          onClick();
        }}
        style={{
          width: 2 * 4.2 + "rem",
          height: "2.2rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "",
          margin: "auto",
          border: "0.15rem solid #4c4c4c",
        }}
        animate={
          !onHover
            ? {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 10%, rgba(23,23,23,1) 100%)",
              }
            : {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 100%, rgba(23,23,23,1) 100%)",
                transition: {
                  duration: 0.5,
                },
              }
        }
      >
        <h3
          style={{
            fontSize: "0.65rem",
          }}
        >
          {param}
        </h3>
      </motion.div>
    </div>
  );
};

export const Button_m = ({ param, onClick, id = "123" }) => {
  const [onHover, setOnHover] = React.useState(false);
  return (
    <div
      style={{
        display: "block",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
      id={id}
    >
      <motion.div
        onClick={() => {
          setOnHover(true);
          onClick();
        }}
        style={{
          width: 2 * 4.8 + "rem",
          height: "2.3rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "",
          margin: "auto",
          border: "0.15rem solid #4c4c4c",
        }}
        animate={
          !onHover
            ? {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 10%, rgba(23,23,23,1) 100%)",
              }
            : {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 100%, rgba(23,23,23,1) 100%)",
                transition: {
                  duration: 0.5,
                },
              }
        }
      >
        <h3
          style={{
            fontSize: "0.6rem",
          }}
        >
          {param}
        </h3>
      </motion.div>
    </div>
  );
};

export function Terms({ term, setInfo }) {
  return (
    <section
      style={{
        display: "flex",
        margin: "0.7rem 0",
      }}
      className="term"
    >
      <div
        onClick={() => {
          setInfo((prev) => {
            return {
              ...prev,
              term: !prev.term,
            };
          });
        }}
      >
        <div
          id="term"
          style={{
            width: "2svh",
            height: "2svh",
            maxWidth: "20px",
            maxHeight: "20px",

            backgroundColor: "#C8C8C8",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "0.5rem",
            border: "none",
          }}
        >
          <div
            style={{
              width: "1.3svh",
              height: "1.3svh",
              maxWidth: "10px",
              maxHeight: "10px",
              backgroundColor: !term ? "#C8C8C8" : "#454545",
              borderRadius: 0,
              border: "none",
            }}
          />
        </div>
      </div>

      <div>
        <p
          style={{
            color: "white",
            fontSize: "0.42rem",
            textAlign: "justify",
            textTransform: "uppercase",
            // lineHeight: "1.1",
            fontFamily: "SackersGothicMediumAT",
          }}
        >
          By clicking "Submit", I agree and grant my consent to vendor to
          collect, use, store and disclose my personal data, which I've provided
          in this registration form, in accordance with vendor’s{" "}
          <span
            style={{
              textDecoration: "underline",
              fontFamily: "SackersGothicMediumAT",
              fontSize: "0.42rem",
            }}
            onClick={() => {
              window.open("/PrivacyPolicy.pdf", "_blank");
            }}
          >
            Privacy Policy
          </span>{" "}
          for providing the following marketing materials that I have agreed to
          receive information sent by vendor about Ralph Lauren Fragrances
          Singapore's products and services, including updates on Ralph Lauren
          Fragrances Singapore's latest promotions and new products and
          services, via SMS.
        </p>
      </div>
    </section>
  );
}

const handerStyle = {
  margin: "0.6rem 0",
  padding: "0.5rem",
  backgroundColor: "#fffff",
};

export function Input({
  placeholder = "First Name",
  size = "100%",
  type = "text",
  maxLength = 4,
  onChange,
}) {
  return (
    <input
      placeholder={placeholder}
      type={type}
      name={placeholder}
      style={{
        textAlign: "center",
        border: "none",
        width: size,
        borderRadius: "0",
        ...handerStyle,
        placeholder: {
          fontSize: "0.7rem",
        },
      }}
      onChange={onChange}
    />
  );
}

export function AgeRange({ onChange }) {
  return (
    <select
      id="age-range"
      placeholder="Age-group"
      name="age-range"
      style={{
        width: "100%",
        textAlign: "center",
        color: "black",
        border: "none",
        borderRadius: "0",
        textAlignLast: "center",
        fontSize: "0.7rem",
        ...handerStyle,
      }}
      onChange={onChange}
    >
      <option value="">AGE-GROUP</option>
      <option value="18-25">18-25</option>
      <option value="26-32">26-32</option>
      <option value="33-41">33-41</option>
      <option value="42-57">42-57</option>
      <option value="58 +">58 +</option>
    </select>
  );
}

export function Gender({ onChange }) {
  return (
    <select
      id="gender"
      placeholder="Gender"
      name="Gender"
      style={{
        width: "100%",
        textAlign: "center",
        color: "black",
        border: "none",
        borderRadius: "0",
        textAlignLast: "center",
        fontSize: "0.7rem",
        ...handerStyle,
      }}
      onChange={onChange}
    >
      <option value="">GENDER</option>
      <option value="Female">FEMALE</option>
      <option value="Male">MALE</option>
    </select>
  );
}

export const LegerLine = ({}) => {
  return (
    <div
      className="footer"
      style={{
        position: "fixed",
        bottom: "2%",
        left: "50%",
        zIndex: 10000000000000,
        transform: "translate(-50%,0)",
        width: "100vw",
        textAlign: "center",
      }}
    >
      <span
        className="mt-auto"
        style={{
          fontSize: "0.3rem",
          color: "white",
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
        }}
      >
        <span
          style={{
            fontSize: "1svh",
            position: "relative",
            top: "-0.03svh",
            marginRight: "0.5vw",
            textTransform: "uppercase",
          }}
        >
          &reg;
        </span>
        {"  "}ALL RIGHTS RESERVED BY RALPH LAUREN FRAGRANCES. POWERED BY{" "}
        <a
          style={{
            color: "white",
            fontSize: "0.8svh",
            textDecoration: "underline",
          }}
          href="https://www.instagram.com/conten.tech/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTEN.T
        </a>
      </span>
    </div>
  );
};

export const DesktopBlock = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100svh",
        backgroundImage: "url(/2d/bg.webp)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="block"
        style={{
          height: "6svh",
        }}
      />
      <div
        style={{
          height: "5svh",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <img src="/2d/logo3.webp" style={{ height: "100%" }} />
      </div>
      <div
        className="block"
        style={{
          height: "6svh",
        }}
      />
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />

      <h2
        style={{
          lineHeight: "1.5",
          fontSize: "2svh",
          margin: "auto",
          width: "fit-content",
          textAlign: "center",
        }}
      >
        THIS EXPERIENCE IS ONLY AVAILABLE ON MOBILE <br />
        PLEASE SCAN THE QR CODE BELOW TO ACCESS
      </h2>

      <div
        className="block"
        style={{
          height: "4svh",
        }}
      />

      <div
        className="imgContainerHeight"
        style={{
          height: "40svh",
          width: "fit-content",
          margin: "auto",
          padding: "3svh",
        }}
      >
        <img
          src="/2d/qrcode.png"
          style={{
            height: "100%",
          }}
        />
      </div>
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <Buttonxl
        param={"LEARN MORE"}
        id="learnmoreDesktop"
        size="100%"
        onClick={() => {
          window.open(
            "https://www.sephora.sg/products/ralph-lauren-ralphs-club-elixir-spray/v/75ml",
            "_blank"
          );
        }}
      />
      <LegerLine />
    </div>
  );
};

export const Buttonxl = ({ param, onClick, color = "" }) => {
  const [onHover, setOnHover] = React.useState(false);
  return (
    <div
      style={{
        display: "block",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <motion.div
        onClick={() => {
          setOnHover(true);
          onClick();
        }}
        style={{
          width: 2 * 7.8 + "rem",
          height: "2.5rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "",
          margin: "auto",
        }}
        animate={
          !onHover
            ? {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 10%, rgba(23,23,23,1) 100%)",
              }
            : {
                background:
                  "linear-gradient(90deg, rgba(38,38,38,1) 0%, #373636 100%, rgba(23,23,23,1) 100%)",
                transition: {
                  duration: 0.5,
                },
              }
        }
      >
        <div
          style={{
            width: "110%",
            height: "130%",
            border: "1px solid #4c4c4c",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          style={{
            width: "105%",
            height: "160%",
            border: "1px solid #4c4c4c",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />{" "}
        <h3
          style={{
            fontSize: "1rem",
            color: color,
          }}
        >
          {param}
        </h3>
      </motion.div>
    </div>
  );
};
