import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";
import { useSuperFan } from "../../context";

const Landing = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const { trackBtn } = useSuperFan();

  const [triggerSvg, setTriggerSvg] = useState(true);
  return (
    <Wrap>
      <MotionWrap>
        <div
          style={{
            width: "100vw",
            height: "100svh",
            maxWidth: "700px",
            overflow: "hidden",
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            pointerEvents: "none",
          }}
        >
          <div
            className="svgComponent"
            style={{
              width: "100svh",
              height: "100svh",
            }}
          >
            <SvgComponent trigger={triggerSvg} />
          </div>
        </div>

        <motion.div animate={trigger ? { opacity: 0 } : { opacity: 1 }}>
          <div className="block" style={{ height: "45svh" }} />

          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "13rem",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <img src="/2d/logo3.webp" style={{ width: "100%" }} />
            </div>
            <div className="block" style={{ height: "3svh" }} />
            <div
              style={{
                height: "15svh",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <img src="/2d/icon.webp" style={{ height: "100%" }} />
            </div>
            <div className="block" style={{ height: "3svh" }} />

            <p
              style={{
                color: "#fff",
              }}
            >
              Come together with us on a Ralph’s Club <br />
              experience, where icons of style and culture
              <br /> meet. Traverse through six immersive zones.
            </p>
          </div>
          <div className="block" style={{ height: "3svh" }} />

          <Button
            param={"ENTER"}
            id={"enterLading"}
            onClick={() => {
              trackBtn("enterLading");
              setTriggerSvg(false);
              setTimeout(() => {
                let info = localStorage.getItem("info");
                if (info === "true") {
                  // reload page to "/"
                  nav("/navpage");
                } else {
                  nav(routeTo);
                }
              }, [2100]);
            }}
          />
        </motion.div>
      </MotionWrap>
    </Wrap>
  );
};

export default Landing;
