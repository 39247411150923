import { Button } from "../helper.jsx";
import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useStore } from "../../store/index.js";
import { useSuperFan } from "../../context";

const color = "#4c4c4c";
const date = Date();

export const Popup = (props) => {
  const { on, setOn } = props;
  const { setIsSoundOn } = useStore((state) => state.actions);
  const [step, setStep] = useState(0);
  const { trackBtn, customType, submit, info } = useSuperFan();

  useMemo(() => {
    console.log(info);
    if (info?.redeem) {
      console.log("redeem");
      setStep(4);
    }
  }, [info]);
  const content = useMemo(() => {
    switch (step) {
      case 0:
        return "STAFF CLAIM";
      case 1:
        return "CONFIRMED";
      case 2:
        customType("redeem", Date());
        submit({
          redeem: Date(),
        });
        return "REDEEMED";
      default:
        return "REDEEMED";
    }
  }, [step]);

  return (
    <motion.div
      initial={{ opacity: 0, top: "100%" }}
      animate={
        !on
          ? { opacity: 0, top: "70%", pointerEvents: "none" }
          : { opacity: 1, top: "50%" }
      }
      transition={{ duration: 0.5, delay: on ? 0 : 0.5 }}
      style={{
        width: "80vw",
        height: "110vw",
        maxWidth: "500px",
        maxHeight: "812px",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        backgroundImage: "linear-gradient(0deg,var(--tw-gradient-stops))",
        "--tw-gradient-from": "#000",
        "--tw-gradient-to": "#262626",
        "--tw-gradient-stops":
          "var(--tw-gradient-from),var(--tw-gradient-to,transparent)",
        padding: "0.5rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div>
          <span
            className="frame"
            style={{
              width: "100%",
              height: "93%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <span
            className="frame"
            style={{
              width: "93%",
              height: "100%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, -50%)",
                right: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, 50%)",
                bottom: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, 50%)",
                bottom: "0",
                right: "0",
              }}
            />

            <div
              className="content"
              style={{
                width: "93%",
                height: "93%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="block" style={{ height: "8%" }} />

              <div
                className="imgContener closeBTN"
                style={{
                  position: "absolute",
                  top: "0.7rem",
                  right: "0.7rem",
                  width: "1rem",
                }}
                onClick={() => {
                  setOn(false);
                }}
              >
                <img src="/2d/closeBTN.webp" style={{ width: "100%" }} />
              </div>

              <div
                className="imgContener"
                style={{
                  height: "8%",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <img src="/2d/logo3.webp" style={{ height: "100%" }} />
              </div>
              <div className="block" style={{ height: "1%" }} />

              <div
                className="imgContener"
                style={{
                  height: "45%",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <img
                  src="/2d/RalphClub_pillowbox_1ratio.webp"
                  style={{ height: "100%" }}
                />
              </div>
              <div
                className="block"
                style={{
                  height: "0%",
                }}
              />

              <div className="block" style={{ height: "0%" }} />

              {step <= 1 ? (
                <p
                  style={{
                    fontSize: "0.6rem",
                    textAlign: "center",
                  }}
                >
                  Do not tap the button below.
                  <br />
                  Present it to our staff for your reward!
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "0.6rem",
                    textAlign: "center",
                  }}
                >
                  Thanks for playing!
                </p>
              )}

              <div className="block" style={{ height: "2%" }} />

              <Button
                param={content}
                id={"staffClaim"}
                color={step >= 2 ? "#737373" : "#fff"}
                onClick={() => {
                  if (step <= 3) {
                    trackBtn("staffClaim");
                    setStep(step + 1);
                  }
                }}
              />

              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                  bottom: "5%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "0.4rem",
                  }}
                >
                  {date}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
