import { Wrap, MotionWrap } from "../helper";
import { useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import "../../css/djbroad.css";
import { Broad } from "./djbroad";
import { PopupOnoff } from "./popuponoff";
import { useStore } from "../../store";
import SvgComponent, { InvertSvg } from "../transition";
import { Popup } from "./popup.js";

export default function DjBroad({ phaserRef, routeTo }) {
  const { isReady } = useStore((state) => state);
  const [triggerSvg, setTriggerSvg] = useState(true);
  const [on, setOn] = useState(false);
  return (
    <Wrap
      backgroundImage="url(/2d/bg.webp)"
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <div
        style={{
          width: "100vw",
          height: "100svh",
          overflow: "hidden",
          position: "absolute",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "100",
          pointerEvents: "none",
        }}
      >
        <Popup on={on} setOn={setOn} />

        <div
          className="svgComponent"
          style={{
            width: "100svh",
            height: "100svh",
          }}
        >
          <SvgComponent trigger={triggerSvg} />
        </div>
      </div>
      <MotionWrap>
        <div style={{ height: "15svh" }} />
        <div>
          <h4
            style={{
              textAlign: "center",
              fontFamily: "SackersGothicMediumAT",
              color: "#fff",
              textTransform: "uppercase",
              fontWeight: "100",
            }}
          >
            Create your own <br />
            Ralph’s Club mix, and discover <br />
            which Ralph’s Club match your <br />
            music taste
          </h4>
        </div>
        <Broad
          phaserRef={phaserRef}
          routeTo={routeTo}
          setTriggerSvg={setTriggerSvg}
          setOn={setOn}
        />
        {isReady && <PopupOnoff />}
      </MotionWrap>
    </Wrap>
  );
}
