import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";
import React from "react";
const Elevator = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();

  return (
    <div
      onClick={() => {
        phaserRef.current.scene.scenes[6].video.play();
      }}
    >
      <div
        className="bg"
        style={{
          width: "100cvw",
          height: "66svh",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "10svh",
            background:
              "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
            position: "relative",
            position: "absolute",
            bottom: "0%",
            zIndex: 10,
          }}
        />
      </div>
      <div className="block" style={{ height: "4svh" }} />

      <h2
        style={{
          textAlign: "center",
        }}
      >
        FROM SINGAPORE
        <br /> TO NEW YORK
      </h2>
      <div className="block" style={{ height: "4svh" }} />

      <p
        style={{
          textAlign: "center",
        }}
      >
        You’re transported to a night so quintessentially <br />
        New York. Explore a mix of fragrance, art, music, <br />
        culture and fashion.
      </p>
    </div>
  );
};

export default Elevator;
