import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSuperFan } from "../../context";

const DJbroad = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const { trackBtn } = useSuperFan();

  return (
    <Wrap>
      <MotionWrap height={"100svh"}>
        <motion.div animate={trigger ? { opacity: 0 } : { opacity: 1 }}>
          <div className="block" style={{ height: "57svh" }} />

          <div
            style={{
              textAlign: "center",
            }}
          >
            <h1>CENTER STAGE</h1>
            <div className="block" style={{ height: "2svh" }} />

            <p>
              Capture your Ralph's Club moment at our <br />
              Photobooth and experience Ralph's Club very own <br />
              digital sound mixer at our Soundboard.
            </p>
          </div>
          <div className="block" style={{ height: "2svh" }} />

          <Button
            param={"PHOTOBOOTH"}
            id={"enterPhotoBooth"}
            onClick={() => {
              // https://gallery.photobooth-ralphsclubsingapore.com/virtual/capture/J0Zw1
              trackBtn("enterPhotoBooth");

              window.open(
                "https://gallery.photobooth-ralphsclubsingapore.com/virtual/capture/J0Zw1",
                "_blank"
              );
            }}
          />
          <div className="block" style={{ height: "0.5svh" }} />

          <Button
            param={"SOUNDBOARD"}
            id="soundboard"
            onClick={() => {
              phaserRef.current.scene.scenes[2].stateUpdate();
              trackBtn("SOUNDBOARD");
              setTrigger(true);
              setTimeout(() => {
                nav(routeTo);
              }, [1000]);
            }}
          />
        </motion.div>
      </MotionWrap>

      {/* <PhotoBooth/> */}
    </Wrap>
  );
};

const PhotoBooth = () => {
  const { trackBtn } = useSuperFan();

  return (
    <div
      style={{
        width: "100vw",
        height: "100svh",
        backgroundColor: "black",
      }}
    >
      <div
        className="bg"
        style={{
          width: "100cvw",
          height: "60svh",
          backgroundImage: "url(/2d/photobooth.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <div
        style={{
          width: "100%",
          height: "10svh",
          background:
            "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
          marginTop: "-7svh",
        }}
      />

      <h2
        style={{
          textAlign: "center",
        }}
      >
        PHOTO BOOTH
      </h2>
      <div className="block" style={{ height: "4svh" }} />

      <p
        style={{
          textAlign: "center",
        }}
      >
        Capture your Ralph’s Club moment
      </p>
      <div className="block" style={{ height: "4svh" }} />

      <Button
        param={"ENTER"}
        id={"enterPhotoBooth"}
        onClick={() => {
          // https://gallery.photobooth-ralphsclubsingapore.com/virtual/capture/J0Zw1
          trackBtn("enterPhotoBooth");

          window.open(
            "https://gallery.photobooth-ralphsclubsingapore.com/virtual/capture/J0Zw1",
            "_blank"
          );
        }}
      />
      <div className="block" style={{ height: "4svh" }} />

      <div
        className="imgContainer"
        style={{
          height: "4%",
          width: "fit-content",
          margin: "0 auto",
        }}
      >
        <img src="/2d/arrow.webp" style={{ height: "100%", width: "auto" }} />
      </div>

      <div
        style={{
          width: "100%",
          height: "10svh",
          background:
            "linear-gradient(180deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
          position: "absolute",
          bottom: "-2rem",
        }}
      />
    </div>
  );
};

export default DJbroad;
