import Phaser from "phaser";
import { Vector3 } from "three";

export class Result extends Phaser.Scene {
  constructor() {
    super("Result");
  }

  init() {
    // load font
  }

  preload() {
    this.load.glsl("bundle", "/glsl/bundle2.glsl");
  }

  create() {
    this.w = this.game.config.width;
    this.h = this.game.config.height;

    // background color
    this.cameras.main.setBackgroundColor("#000000");
    let diff = this.w - (this.h * 55) / 100;

    let type = localStorage.getItem("bass");
    if (type < 0.3) {
      type = 3;
    } else if (type < 0.7) {
      type = 1;
    } else {
      type = 2;
    }

    this.shader = this.add.shader(
      "Stripes",
      this.w / 2,
      this.w / 2 - diff * 1.1,
      this.w * 1.1,
      this.w
    );
    this.shader.setChannel0("result_m_" + type);
    this.shader.setChannel1("noice");
    // sprite

    this.img = this.add.sprite(this.w / 2, this.w / 2 - diff, "result" + type);
    this.img.setDisplaySize(this.w, this.w);

    this.gradient = this.add.sprite(
      this.w / 2,
      this.w - diff * 1.1,
      "gradient"
    );
    this.gradient.setDisplaySize(this.w, this.w * 0.076);

    this.info = this.add.sprite(
      this.w / 2,
      this.w + this.h * 0.32 * 0.4 - diff,
      "info" + type
    );
    this.info.setDisplaySize(this.h * 2.04 * 0.2, this.h * 0.2);

    // to djbroad
    // shader uniforms

    window.changeType = this.changeType.bind(this);
  }

  changeType(type) {
    this.img.setTexture("result" + type);
    this.info.setTexture("info" + type);
    this.shader.setChannel0("result_m_" + type);
  }

  toScene(scene) {
    this.scene.start(scene);
  }
}
