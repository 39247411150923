import { Button } from "../helper";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useStore } from "../../store/index.js";
import { useSuperFan } from "../../context";
const color = "#4c4c4c";

export const PopupOnoff = () => {
  const [on, setOn] = useState(false);
  const { setIsSoundOn } = useStore((state) => state.actions);
  const { trackBtn } = useSuperFan();
  return (
    <motion.div
      initial={{ opacity: 0, top: "100%" }}
      animate={
        on
          ? { opacity: 0, top: "70%", pointerEvents: "none" }
          : { opacity: 1, top: "50%" }
      }
      transition={{ duration: 0.5, delay: on ? 0 : 0.5 }}
      style={{
        width: "90vw",
        height: "90vw",
        maxWidth: "700px",
        maxHeight: "500px",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        backgroundImage: "linear-gradient(0deg,var(--tw-gradient-stops))",
        "--tw-gradient-from": "#000",
        "--tw-gradient-to": "#262626",
        "--tw-gradient-stops":
          "var(--tw-gradient-from),var(--tw-gradient-to,transparent)",
        padding: "0.5rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div>
          <span
            className="frame"
            style={{
              width: "100%",
              height: "93%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <span
            className="frame"
            style={{
              width: "93%",
              height: "100%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span
              className="frame"
              style={{
                width: "17%",
                height: "17%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "17%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, -50%)",
                right: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "17%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, 50%)",
                bottom: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "17%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, 50%)",
                bottom: "0",
                right: "0",
              }}
            />

            <div
              className="content"
              style={{
                width: "93%",
                height: "93%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="block" style={{ height: "15%" }} />
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Adjust the volume controls to experiment <br /> with and remix
                the Ralph's Club track.
              </p>

              <div
                className="block"
                style={{
                  height: "10%",
                }}
              />
              <Button
                param={"SOUND ON"}
                id="soundOn"
                onClick={() => {
                  trackBtn("soundOn");
                  setIsSoundOn(true);
                  setOn(true);
                }}
              />
              <div
                className="block"
                style={{
                  height: "1svh",
                }}
              />
              <Button
                param={"CLOSE"}
                id="closeMusic"
                onClick={() => {
                  trackBtn("closeMusic");
                  setIsSoundOn(false);
                  setOn(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
