import React, { useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import PhaserApp from "./2d";
import {
  DjBroadBroad,
  Djbroad,
  Form,
  NavBar,
  Landing,
  NavPage,
  OlfactiveBar,
  VIP,
  FashionWalkway,
  Welcome,
  Elevator,
  Prize,
  Result,
} from "./overlay";
import "./app.scss";
import { useStore } from "./store/index.js";
import { useLocation } from "react-router-dom";
import Preload from "../src/overlay/preload";
import { LegerLine } from "./overlay/helper";
function App() {
  const phaserRef = React.useRef(null);
  const { init, isReady, isSoundOn } = useStore((state) => state);

  React.useEffect(() => {
    init();
  }, []);

  useMemo(() => {
    if (phaserRef.current) {
      if (!isSoundOn) {
        ["bass", "horns", "percussion", "piano", "sfx", "vox"].forEach(
          (type) => {
            phaserRef.current.scene.scenes[0][type].volume = 0;
          }
        );
      } else {
        ["bass", "horns", "percussion", "piano", "sfx", "vox"].forEach(
          (type) => {
            let volume = localStorage.getItem(type) ?? 0.5;
            phaserRef.current.scene.scenes[0][type].volume = volume;
          }
        );
      }
    }
  }, [isSoundOn]);

  return (
    <>
      <NavBar phaserRef={phaserRef} />
      <PhaserApp ref={phaserRef} />
      <Preload isloaded={isReady} />
      <LegerLine />
      {/* route */}
      <Routes>
        <Route
          index
          element={<Landing phaserRef={phaserRef} routeTo={"/form"} />}
        />

        <Route
          path="form"
          element={<Form phaserRef={phaserRef} routeTo={"/navpage"} />}
        />

        <Route
          path="olfactive-bar"
          element={<OlfactiveBar phaserRef={phaserRef} routeTo={"/navpage"} />}
        />

        <Route
          path="welcome"
          element={<Welcome phaserRef={phaserRef} routeTo={"/elevator"} />}
        />

        <Route
          path="fashion-walkway"
          element={
            <FashionWalkway phaserRef={phaserRef} routeTo={"/elevator"} />
          }
        />

        <Route
          path="elevator"
          element={<Elevator phaserRef={phaserRef} routeTo={"/navpage"} />}
        />

        <Route
          path="vip"
          element={<VIP phaserRef={phaserRef} routeTo={"/navpage"} />}
        />

        <Route
          path="center-stage"
          element={
            <Djbroad phaserRef={phaserRef} routeTo={"/center-stage/broad"} />
          }
        />

        <Route
          path="/center-stage/broad"
          element={
            <DjBroadBroad
              phaserRef={phaserRef}
              routeTo={"/center-stage/result"}
            />
          }
        />

        <Route
          path="/center-stage/result"
          element={<Result phaserRef={phaserRef} routeTo={"/"} />}
        />

        <Route path="navpage" element={<NavPage phaserRef={phaserRef} />} />
      </Routes>
    </>
  );
}

export default App;
