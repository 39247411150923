import Phaser from "phaser";

export class Video2 extends Phaser.Scene {
  constructor() {
    super("video2");
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    this.cameras.main.setBackgroundColor("#fff");
    this.cameras.main.setBackgroundColor("#000000");

    this.w = this.game.config.width;
    this.h = this.game.config.height;

    // // add video
    this.video = this.add.video(this.w / 2, this.h * 0.3, "RL_Elevator");
    // set video size phaser js
    this.video.setScale((this.h * 0.66) / 500);

    // play loop
    this.video.play(true);
  }

  toScene(scene) {
    this.scene.start(scene);
  }
}
