import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";

const OlfactiveBar = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  return (
    <Wrap background="black">
      <MotionWrap>
        <div
          className="bg"
          style={{
            width: "100vw",
            height: "50svh",
            maxWidth: "700px",
            maxHeight: "700px",
            backgroundImage: "url(/2d/OLFACTIVE.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </MotionWrap>

      <div
        style={{
          width: "100%",
          height: "10svh",
          background:
            "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
          marginTop: "-7svh",
        }}
      />
      <div className="block" style={{ height: "3svh" }} />

      <h2
        style={{
          textAlign: "center",
        }}
      >
        OLFACTIVE BAR
        <br />
        <span style={{ opacity: 0 }}>Block</span>
      </h2>
      {/* <div className="block" style={{ height: "2svh" }} /> */}

      <h2
        style={{
          textAlign: "center",
          fontSize: "0.5rem",
          textTransform: "uppercase",
        }}
      >
        unforgettable. sophisticated. authentic.
      </h2>
      <div className="block" style={{ height: "2svh" }} />

      <p
        style={{
          textAlign: "center",
        }}
      >
        A fragrance collection curated for the confident,
        <br /> sophisticated who inspires togetherness and enjoys
        <br /> the fullness of life
      </p>

      <div
        style={{
          textAlign: "center",
          bottom: "0",
          position: "absolute",
          width: "100%",
          height: "18svh",
          borderTop: "1px solid #737373",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="imgContainer"
          style={{
            width: "50%",
            marginBottom: "2svh",
          }}
          onClick={() => {
            // https://www.ralphlauren.com
            window.open(
              "https://www.sephora.sg/products/ralph-lauren-ralphs-club-elixir-spray/v/75ml",
              "_blank"
            );
          }}
        >
          <img src="/2d/logo4.webp" style={{ width: "100%" }} alt="shopelink" />
        </div>
      </div>
    </Wrap>
  );
};

export default OlfactiveBar;
