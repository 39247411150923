import Phaser from "phaser";

export class Door extends Phaser.Scene {
  constructor() {
    super("door");
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    this.cameras.main.setBackgroundColor("#fff");
    this.cameras.main.setBackgroundColor("#000000");

    this.w = this.game.config.width;
    this.h = this.game.config.height;

    // // add video
    this.video = this.add.video(this.w / 2, this.w / 2, "RL_Door_Open");
    // set video size phaser js
    this.video.setScale(this.w / 700);

    this.door = this.add.sprite(
      this.w / 2,
      this.w / 2 + this.h * 0.1,
      "dorrOpen"
    );
    this.door.setDisplaySize(this.w, this.w);

    // this.door.anims.play("doorOpen");
  }

  toScene(scene) {
    this.scene.start(scene);
  }
}
