import Phaser from "phaser";

export class Bootstrap extends Phaser.Scene {
  constructor() {
    super("bootstrap");
  }

  init() {}

  preload() {
    // bass horns percusssion piaono sfx vox
    this.load.audio("Bass", "/mp3/Bass.mp3");
    this.load.audio("Horns", "/mp3/Horns.mp3");
    this.load.audio("Percussion", "/mp3/Percussion.mp3");
    this.load.audio("Piano", "/mp3/Piano.mp3");
    this.load.audio("SFX", "/mp3/SFX.mp3");
    this.load.audio("Vox", "/mp3/Vox.mp3");

    // state
    this.load.image("state", "/2d/state.webp");
    this.load.image("state2", "/2d/bg.webp");
    this.load.image("door", "/2d/door.webp");
    this.load.image("result1", "/2d/result1.webp");
    this.load.image("result2", "/2d/result2.webp");
    this.load.image("result3", "/2d/result3.webp");
    this.load.image("noice", "/2d/noice.webp");
    this.load.image("info1", "/2d/info1.webp");
    this.load.image("info2", "/2d/info2.webp");
    this.load.image("info3", "/2d/info3.webp");

    this.load.image("result_m_1", "/2d/result_m_1.webp");
    this.load.image("result_m_2", "/2d/result_m_2.webp");
    this.load.image("result_m_3", "/2d/result_m_3.webp");
    this.load.image("gradient", "/2d/gradient.webp");

    // load video
    this.load.video(
      "RL_Walkway",
      "/2d/RL_Walkway.mp4",
      "canplaythrough",
      false
    );
    this.load.video("RL_Elevator", "/2d/RL_GIF.mp4", "canplaythrough", false);

    this.load.spritesheet("dorrOpen", "/2d/Door_open_spritesheet.webp", {
      frameWidth: 400,
      frameHeight: 400,
    });
  }

  create() {
    this.w = this.game.config.width;
    this.h = this.game.config.height;
    this.bass = this.sound.add("Bass");
    this.horns = this.sound.add("Horns");
    this.percussion = this.sound.add("Percussion");
    this.piano = this.sound.add("Piano");
    this.sfx = this.sound.add("SFX");
    this.vox = this.sound.add("Vox");

    // set height of state
    this.state = this.add.sprite(this.w / 2, (this.h * 0.7) / 2, "door");
    this.state.setDisplaySize(this.h * 1.024 * 0.7, this.h * 0.7);

    this.cameras.main.setBackgroundColor("#000000");

    // create animation
    this.anims.create({
      key: "doorOpen",
      frames: this.anims.generateFrameNumbers("dorrOpen", {
        start: 0,
        end: 20,
      }),
      frameRate: 20,
      repeat: 0,
    });

    window.setIsReady(true);

    // check current router
    if (window.location.pathname.includes("result")) {
      console.log("result");
      this.scene.start("Result");
    } else if (window.location.pathname.includes("center-stage")) {
      this.scene.start("djBroadPre");
    } else if (window.location.pathname.includes("fashion-walkway")) {
      this.scene.start("video");
    } else if (window.location.pathname.includes("welcome")) {
      this.scene.start("door");
    } else if (window.location.pathname.includes("elevator")) {
      this.scene.start("video2");
    } else if (
      window.location.pathname.includes("center-stage") &&
      window.location.pathname.includes("broad")
    ) {
      this.scene.start("djbroad");
    }
  }

  stateUpdate() {
    // this.tweens.add({
    //   targets: this.state,
    //   displayHeight: this.h * 0.95,
    //   displayWidth: this.h * 0.95,
    //   y: this.h * 0.45,
    //   duration: 1000,
    //   alpha: 0,

    //   onComplete: () => {
    //     this.scene.start("djbroad");
    //   },
    // });

    this.time.delayedCall(2000, () => {
      this.scene.start("djBroadPre");
    });
  }

  toScene(scene) {
    this.scene.start(scene);
  }

  update() {}
}

function ArrayFrame(start, end, reverse) {
  if (reverse) {
    let arr = [];
    for (let i = start; i >= end; i--) {
      arr.push({ key: "light" + i.toString().padStart(1, "0") });
    }
    return arr;
  } else {
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push({ key: "light" + i.toString().padStart(1, "0") });
    }
    return arr;
  }
}
