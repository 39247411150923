import * as React from "react";
import { motion } from "framer-motion";
import { useStore } from "../../store";
const SvgComponent = (props) => {
  const { trigger = true, ...rest } = props;
  const control = 250;
  const controlEnd = -71;
  const _duration = 1.5;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: "new 0 0 100svh 100svh",
        height: "100svh",
        width: "100svh",
      }}
      viewBox="0 0 500 500"
      {...rest}
    >
      <motion.path
        initial={{
          d: `M0,0v500h500V0H0z  m250 ${373.5 + control * 1.4} l${
            -71 - control
          }.9-${124.1 + control * 1.4} L250 ${126.5 - control * 1.4} l${
            71.9 + control
          } ${122.9 + control * 1.4}z`,
        }}
        animate={
          trigger
            ? {
                d: `M0,0v500h500V0H0z  m250 ${373.5 + control * 1.4} l${
                  -71 - control
                }.9-${124.1 + control * 1.4} L250 ${126.5 - control * 1.4} l${
                  71.9 + control
                } ${122.9 + control * 1.4}z`,
              }
            : {
                d: `M0,0v500h500V0H0z  m250 ${373.5 + controlEnd * 1.4} l${
                  -71 - controlEnd
                }.9-${124.1 + controlEnd * 1.4} L250 ${
                  126.5 - controlEnd * 1.4
                } l${71.9 + controlEnd} ${122.9 + controlEnd * 1.4}z`,
              }
        }
        transition={{
          duration: _duration,
        }}
      />
    </svg>
  );
};

export default SvgComponent;

export const InvertSvg = (props) => {
  const { trigger = true, ...rest } = props;
  const control = -50;
  const controlEnd = 250;
  const _duration = 1.5;
  const { isReady } = useStore((state) => state);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: "new 0 0 100svh 100svh",
        height: "100svh",
        width: "100svh",
      }}
      viewBox="0 0 500 500"
      {...rest}
    >
      <motion.path
        initial={{
          d: `M0,0v500h500V0H0z  m250 ${373.5 + control * 1.4} l${
            -71 - control
          }.9-${124.1 + control * 1.4} L250 ${126.5 - control * 1.4} l${
            71.9 + control
          } ${122.9 + control * 1.4}z`,
        }}
        animate={
          isReady &&
          trigger && {
            d: `M0,0v500h500V0
            H0z  m250 ${373.5 + controlEnd * 1.4} l${-71 - controlEnd}.9-${
              124.1 + controlEnd * 1.4
            } L250 ${126.5 - controlEnd * 1.4} l${71.9 + controlEnd} ${
              122.9 + controlEnd * 1.4
            }z`,
          }
        }
        transition={{
          duration: _duration,
        }}
      />
    </svg>
  );
};
