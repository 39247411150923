import { Button_s, Wrap } from "../helper.jsx";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import Speaker from "./speaker.js";
import { motion } from "framer-motion";
import { useStore } from "../../store/index.js";
import { useSuperFan } from "../../context/index.jsx";

// broad

localStorage.setItem("bass", 0.5);
localStorage.setItem("horns", 0.5);
localStorage.setItem("percussion", 0.5);
localStorage.setItem("piano", 0.5);
localStorage.setItem("sfx", 0.5);
localStorage.setItem("vox", 0.5);

export const Broad = ({ phaserRef, routeTo, setTriggerSvg, setOn }) => {
  const { isReady } = useStore((state) => state);
  const nav = useNavigate();
  const { trackBtn, customType, submit } = useSuperFan();

  return (
    <div
      style={{
        backgroundImage:
          "radial-gradient(circle at 50% 50%,hsla(0,0%,100%,.3),rgba(0,0,0,.5) 85%),linear-gradient(180deg,#1d1d1d,#131213)",
        border: "8px solid rgba(60,60,60,.4)",
        borderRadius: "0",
        margin: "auto",
        height: "fit-content",
        width: "100%",

        position: "absolute",
        bottom: "0",
      }}
    >
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <div
        className="imgContainer"
        style={{
          margin: "auto",
          width: "88%",
          maxWidth: "400px",
        }}
      >
        <img src="/2d/logo.webp" />
      </div>
      <div
        className="block"
        style={{
          width: "100%",
          height: "10svh",
        }}
      ></div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(11, 1fr)",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <Track type="bass" phaserRef={phaserRef} isReady={isReady} />
        <SolidLine />
        <Track type="horns" phaserRef={phaserRef} isReady={isReady} />
        <SolidLine />
        <Track type="percussion" phaserRef={phaserRef} isReady={isReady} />
        <SolidLine />
        <Track type="piano" phaserRef={phaserRef} isReady={isReady} />
        <SolidLine />
        <Track type="sfx" phaserRef={phaserRef} isReady={isReady} />
        <SolidLine />
        <Track type="vox" phaserRef={phaserRef} isReady={isReady} />
      </div>
      <div
        className="block"
        style={{
          width: "100%",
          height: "8svh",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button_s
          param={"share"}
          id="share"
          onClick={() => {
            trackBtn("share");
            setOn(true);
          }}
        />
        <div style={{ width: "1rem" }} />
        <Button_s
          param={"generate"}
          id="generate"
          onClick={() => {
            let type = localStorage.getItem("bass");
            if (type < 0.3) {
              type = 3;
              customType("outcome", "edp");
              submit({ outcome: "edp" });
            } else if (type < 0.7) {
              type = 1;
              customType("outcome", "parfum");
              submit({ outcome: "parfum" });
            } else {
              type = 2;
              customType("outcome", "elixir");
              submit({ outcome: "elixir" });
            }

            phaserRef.current.scene.start("Result");
            trackBtn("generate");
            setTriggerSvg(false);
            setTimeout(() => {
              nav(routeTo);
            }, 1600);
          }}
        />
      </div>

      <div
        className="block"
        style={{
          width: "100%",
          height: "7svh",
        }}
      ></div>
    </div>
  );
};

export const min = 0.08;
const Track = ({
  phaserRef,
  type = "bass",
  isReady,
  size = "25svh",
  maxLen = "10rem",
}) => {
  const [currenttrack, setCurrenttrack] = React.useState(0.08 * 5 * 100);
  const sliderRef = React.useRef(null);
  const { currentInstrument, isSoundOn } = useStore((state) => state);
  const { setIsSoundOn } = useStore((state) => state.actions);
  const [isPlaying, setIsPlaying] = React.useState(false);

  useMemo(() => {
    const location = window.location.href;
    // get Query
    const query = location.split("?")[1] ?? false;
    if (!query) return;
    const __type = query.split("&");
    __type.map((item) => {
      let _type = item.split("=")[0];
      let _value = item.split("=")[1];

      if (type === _type) {
        setCurrenttrack(_value * 100);
      }
    });
  }, []);

  useMemo(() => {
    if (isReady && sliderRef.current) {
      if (currentInstrument !== null && currentInstrument !== type) {
        phaserRef.current.scene.scenes[0][type].volume = 0;
        setCurrenttrack(min * 100);
        sliderRef.current.value = min;
      } else if (currentInstrument === type) {
        phaserRef.current.scene.scenes[0][type].volume = (min * 5) / 100;
        setCurrenttrack(min * 5 * 100);
        sliderRef.current.value = min * 5;
      }
      localStorage.setItem(type, currenttrack / 100);
    }
  }, [currentInstrument]);

  useMemo(() => {
    if (isReady) {
      if (currenttrack === 0.08 * 100) {
        phaserRef.current.scene.scenes[0][type].volume = 0;
      } else {
        setIsSoundOn(true);
        phaserRef.current.scene.scenes[0][type].volume = currenttrack / 100;
      }

      localStorage.setItem(type, currenttrack / 100);
    }
  }, [isReady, currenttrack]);

  useMemo(() => {
    if (isReady) {
      if (isSoundOn) {
        phaserRef.current.scene.scenes[0][type].volume = currenttrack / 100;
      } else {
        phaserRef.current.scene.scenes[0][type].volume = 0;
      }
      if (!isPlaying) {
        phaserRef.current.scene.scenes[0][type].play({ loop: true });
        setIsPlaying(true);
      }
    }
  }, [isReady, isSoundOn]);

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      {/* after */}
      <Indicator
        _type={type}
        setCurrenttrack={setCurrenttrack}
        sliderRef={sliderRef}
        currenttrack={currenttrack}
      />
      <Speakers
        _type={type}
        setCurrenttrack={setCurrenttrack}
        sliderRef={sliderRef}
        currenttrack={currenttrack}
      />
      <section
        style={{
          width: "8vw",
          maxWidth: maxLen,
        }}
        className="trackContainer"
      >
        <div
          style={{
            height: size,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            flexDirection: "column",
          }}
        >
          <input
            type="range"
            min={0.08}
            max={0.8}
            // start with
            value={currenttrack / 100}
            step={0.08}
            style={{
              transform: "rotate(-90deg)",
              width: size, // Make the width equal to the viewport height
            }}
            ref={sliderRef}
            onChange={(e) => {
              setCurrenttrack((e.target.value / 1) * 100);
            }}
          />

          <div
            className="imgContainer"
            style={{
              position: "absolute",
              bottom: currenttrack + "%",
              left: "50%",
              transform: "translate(-50%, 30%)",
              pointerEvents: "none",
              width: "15vw",
              maxWidth: "110px",
            }}
          >
            <img
              src="/2d/rangeslider-thumb.webp"
              style={
                {
                  // transform: "rotate(90deg)",
                }
              }
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const SolidLine = ({ color = "white" }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundImage:
            "linear-gradient(90deg, transparent 5px, transparent 0), linear-gradient(#b5b5b5 2px, transparent 0)",
          backgroundSize: "200% 2.5svh",
          width: "1.6vw",
          maxWidth: "2.5rem",
          height: "90%",
        }}
      />
    </div>
  );
};

const Indicator = ({
  _type = "bass",
  setCurrenttrack,
  sliderRef,
  currenttrack = 0.08 * 100,
}) => {
  const type = useMemo(() => {
    switch (_type) {
      case "bass":
        return "BASS";
      case "horns":
        return "HORNS";
      case "percussion":
        return "DRUMS";
      case "piano":
        return "PIANO";
      case "sfx":
        return "EFFECTS";
      case "vox":
        return "VOCALS";
    }
  }, [_type]);

  const { setInstrument } = useStore((state) => state.actions);

  return (
    <div
      style={{
        position: "absolute",
        top: "-25%",
        left: "50%",
        transform: "translate(-57%, 0)",
      }}
      onClick={() => {
        setInstrument(_type);
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          className="circle"
          style={{
            width: "1.5svh",
            height: "1.5svh",
            borderRadius: "50%",
            backgroundImage:
              currenttrack === min * 100
                ? "radial-gradient(circle at 50% 50%, hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0.1) 71%)"
                : "radial-gradient(circle at 50% 50%,#fff,#8d91a4 71%)",
            margin: "auto",
            boxShadow: "0 0 4px 0 #7f87a9",
          }}
        />
      </div>

      <div
        style={{
          marginTop: "0.5rem",
        }}
      >
        <p
          style={{
            fontSize: "0.5rem",
          }}
        >
          {type}
        </p>
      </div>
    </div>
  );
};

const Speakers = ({
  _type,
  setCurrenttrack,
  sliderRef,
  currenttrack = 0.08 * 100,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "-25%",
        left: "50%",
        transform: "translate(-57%, 0)",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <motion.div
          className="circle"
          animate={
            currenttrack !== min * 100
              ? {
                  backgroundImage: "linear-gradient(180deg,#333,#080808)",
                }
              : {
                  backgroundImage: "linear-gradient(180deg,#4e4e4e,#1c1c1c)",
                }
          }
          style={{
            width: "1.4rem",
            height: "1.4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            transition: "0.5",
            margin: "auto",
            boxShadow: "0 3px 4px 0 #373738",
          }}
          onClick={() => {
            if (currenttrack === min * 100) {
              setCurrenttrack(5 * min * 100);
              sliderRef.current.value = 5 * min;
            } else {
              setCurrenttrack(min * 100);
              sliderRef.current.value = min;
            }
          }}
        >
          <Speaker currenttrack={currenttrack} />
        </motion.div>
      </div>
    </div>
  );
};
