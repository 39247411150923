import _DjBroadBroad from "./djbroadbroad";
import _NavBar from "./navbar";
import _Djbroad from "./centerStage";
import _Form from "./Form";
import _Landing from "./landing";
import _NavPage from "./navPage";
import _OlfactiveBar from "./olfactiveBar";
import _Elevator from "./Elevator";
import _VIP from "./VIP";
import _FashionWalkway from "./Walkway";
import _Welcome from "./Welcome";
import _Prize from "./prize";
import _Result from "./result";

export const DjBroadBroad = _DjBroadBroad;
export const NavBar = _NavBar;
export const Djbroad = _Djbroad;
export const Form = _Form;
export const Landing = _Landing;
export const NavPage = _NavPage;
export const OlfactiveBar = _OlfactiveBar;
export const Elevator = _Elevator;
export const VIP = _VIP;
export const FashionWalkway = _FashionWalkway;
export const Welcome = _Welcome;
export const Prize = _Prize;
export const Result = _Result;
