import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";
import { useSuperFan } from "../../context";

const Welcome = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  const { trackBtn } = useSuperFan();

  return (
    <Wrap>
      <div style={{ height: "12svh" }} />
      <MotionWrap>
        <div
          className="bg"
          style={{
            width: "100vw",
            height: "55svh",
            overflow: "hidden",
          }}
        >
          {/* <img src="/2d/door.jpg" style={{ width: "100%" }} /> */}
          {/* video */}
        </div>
      </MotionWrap>
      <div
        style={{
          marginTop: "-12svh",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "10svh",
            background:
              "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
            marginTop: "-7svh",
          }}
        />

        <h2
          style={{
            textAlign: "center",
          }}
        >
          WELCOME TO
          <br />
          RALPH’S CLUB
        </h2>
        <div className="block" style={{ height: "4svh" }} />

        <p
          style={{
            textAlign: "center",
          }}
        >
          Come together with Ralph’s Club as we transport
          <br />
          you from Singapore to New York
        </p>
      </div>

      <div className="block" style={{ height: "2svh" }} />

      <Button
        param={"ENTER"}
        id={"enterWelcome"}
        onClick={() => {
          trackBtn("enterWelcome");
          phaserRef.current.scene.scenes[5].door.play("doorOpen");
          setTimeout(() => {
            phaserRef.current.scene.scenes[5].toScene("video2");
            nav(routeTo);
          }, 1000);
        }}
      />
    </Wrap>
  );
};

export default Welcome;
