import { useNavigate } from "react-router-dom";
import { Wrap, Button, Button_m, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";
import { Popup } from "./popup";
import React from "react";
import { useSuperFan } from "../../context";
import { useStore } from "../../store";

const Result = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  const [triggerSvg, setTriggerSvg] = React.useState(true);
  const [on, setOn] = useState(false);
  const { trackBtn, customType, submit } = useSuperFan();

  return (
    <Wrap>
      <Popup on={on} setOn={setOn} />
      <div
        style={{
          width: "100vw",
          height: "100svh",
          overflow: "hidden",
          position: "absolute",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "100",
          pointerEvents: "none",
        }}
      >
        <div
          className="svgComponent"
          style={{
            width: "100svh",
            height: "100svh",
          }}
        >
          <InvertSvg trigger={triggerSvg} />
        </div>
      </div>
      <div className="block" style={{ height: "80svh" }} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button_m
          param={"CLAIM GIFT"}
          id="claim_gift"
          onClick={() => {
            trackBtn("claim_gift");
            setOn(true);
          }}
        />
        <div className="block" style={{ width: "1rem" }} />
        <Button_m
          param={"SHOP NOW"}
          id="shopnow"
          onClick={() => {
            // https://www.ralphlauren.com
            trackBtn("shop_now");
            window.open(
              "https://www.sephora.sg/products/ralph-lauren-ralphs-club-elixir-spray/v/75ml",
              "_blank"
            );
          }}
        />
      </div>
    </Wrap>
  );
};

export default Result;
