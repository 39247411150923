import { motion } from "framer-motion";

const Preload = ({ isloaded }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100svh",
        backgroundColor: "black",
        position: "absolute",
        zIndex: "100",
        top: "0",
        pointerEvents: isloaded ? "none" : "all",
        opacity: isloaded ? "0" : "1",
        transition: "0.5s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div
        className="imgContainer"
        style={{
          width: "6rem",
          height: "6rem",
        }}
        animate={{
          //skewX
          rotateY: [0, 360],
          transition: {
            duration: 2,
            repeat: Infinity,
            delay: 0.5,
          },
        }}
      >
        <img src="/2d/favicon.svg" />
      </motion.div>
    </div>
  );
};

export default Preload;
