import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";
import { useSuperFan } from "../../context";

const VIP = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  const { trackBtn } = useSuperFan();

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100svh",
          backgroundColor: "black",
        }}
      >
        <div
          className="bg"
          style={{
            width: "100cvw",
            height: "66svh",
            backgroundImage: "url(/2d/viplounge.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10svh",
              background:
                "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
              position: "relative",
              position: "absolute",
              bottom: "0%",
              zIndex: 10,
            }}
          />
        </div>
        <div className="block" style={{ height: "4svh" }} />

        <h2
          style={{
            textAlign: "center",
          }}
        >
          VIP LOUNGE
        </h2>
        <div className="block" style={{ height: "4svh" }} />

        <p
          style={{
            textAlign: "center",
          }}
        >
          Come together and enjoy your Ralph’s Club
          <br /> moments with us!
        </p>
        <div className="block" style={{ height: "4svh" }} />
      </div>
    </>
  );
};

export default VIP;
