import * as React from "react";
import { min } from "./djbroad";
const SvgComponent = (props) => {
  const { currentTrack = 0.08 * 100 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={13}
      className="Icon MuteIcon fill-current transition-opacity duration-700"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M9.5 1.5a.5.5 0 0 0-.739-.439L3.373 4H1a.504.504 0 0 0-.354.146A.504.504 0 0 0 .5 4.5v4A.5.5 0 0 0 1 9h2.373l5.388 2.939a.498.498 0 0 0 .494-.01.497.497 0 0 0 .245-.429v-10zM4 4.797l4.5-2.455v8.316L4 8.203V4.797zM3 5H1.5v3H3V5z"
        />
        {currentTrack === 0.08 * 100 ? (
          <path stroke="#FFF" strokeWidth={0.8} d="m1.5 1 11 10.587" />
        ) : (
          <></>
        )}
      </g>
    </svg>
  );
};
export default SvgComponent;
