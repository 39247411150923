import Phaser from "phaser";

export class DjBroadPre extends Phaser.Scene {
  constructor() {
    super("djBroadPre");
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    this.cameras.main.setBackgroundColor("#fff");

    this.w = this.game.config.width;
    this.h = this.game.config.height;

    // set height of state
    this.state = this.add.sprite(this.w / 2, this.w / 2, "state");
    this.state.setDisplaySize(this.w * 1.05, this.w * 1.05);

    this.cameras.main.setBackgroundColor("#000000");

    // bass horns percusssion piaono sfx vox

    // play loop
    // this.bass.play({ loop: true });
    // this.horns.play({ loop: true });
    // this.percussion.play({ loop: true });
    // this.piano.play({ loop: true });
    // this.sfx.play({ loop: true });
    // this.vox.play({ loop: true });
  }

  stateUpdate() {
    this.tweens.add({
      targets: this.state,
      displayHeight: this.w * 1.2,
      displayWidth: this.w * 1.2,
      y: this.h * 0.35,
      duration: 1000,
      alpha: 0,
      onComplete: () => {
        this.scene.start("djbroad");
      },
    });
  }
}
