import { Button } from "../helper.jsx";
import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useStore } from "../../store/index.js";
import { useSuperFan } from "../../context";

const color = "#4c4c4c";
const date = Date();

export const Popup = (props) => {
  const { on, setOn } = props;
  const { setIsSoundOn } = useStore((state) => state.actions);
  const [step, setStep] = useState(0);
  const { trackBtn } = useSuperFan();

  return (
    <motion.div
      initial={{ opacity: 0, top: "100%" }}
      animate={
        !on
          ? { opacity: 0, top: "70%", pointerEvents: "none" }
          : { opacity: 1, top: "50%", pointerEvents: "auto" }
      }
      transition={{ duration: 0.5, delay: on ? 0 : 0.5 }}
      style={{
        width: "80vw",
        height: "110vw",
        maxWidth: "500px",
        maxHeight: "812px",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10000",
        backgroundImage: "linear-gradient(0deg,var(--tw-gradient-stops))",
        "--tw-gradient-from": "#000",
        "--tw-gradient-to": "#262626",
        "--tw-gradient-stops":
          "var(--tw-gradient-from),var(--tw-gradient-to,transparent)",
        padding: "0.5rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div>
          <span
            className="frame"
            style={{
              width: "100%",
              height: "93%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <span
            className="frame"
            style={{
              width: "93%",
              height: "100%",
              border: `1px solid ${color}`,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, -50%)",
                right: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(-50%, 50%)",
                bottom: "0",
              }}
            />

            <span
              className="frame"
              style={{
                width: "17%",
                height: "13%",
                border: `1px solid ${color}`,
                position: "absolute",
                borderRadius: "50%",
                transform: "translate(50%, 50%)",
                bottom: "0",
                right: "0",
              }}
            />

            <div
              className="content"
              style={{
                width: "93%",
                height: "93%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "100",
              }}
            >
              <div className="block" style={{ height: "8%" }} />

              <div
                className="imgContener closeBTN"
                style={{
                  position: "absolute",
                  top: "0.7rem",
                  right: "0.7rem",
                  width: "1rem",
                }}
                onClick={() => {
                  setOn(false);
                }}
              >
                <img src="/2d/closeBTN.webp" style={{ width: "100%" }} />
              </div>

              <div
                className="imgContener"
                style={{
                  height: "8%",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <img src="/2d/logo3.webp" style={{ height: "100%" }} />
              </div>
              <div className="block" style={{ height: "5%" }} />

              <h3 style={{ textAlign: "center" }}>SHARE YOUR MIX</h3>

              <div className="block" style={{ height: "5%" }} />

              <div
                className="imgContener"
                style={{
                  height: "15%",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <img src="/2d/icon.webp" style={{ height: "100%" }} />
              </div>
              <div className="block" style={{ height: "5%" }} />

              <p style={{ textAlign: "center" }}>
                Share your Ralph’s Club mix
                <br />
                with your friends!
              </p>

              <div
                className="block"
                style={{
                  height: "1svh",
                }}
              />
              <Button
                param={"COPY SHARE LINK"}
                zIndex="100"
                id={"copyLink"}
                onClick={() => {
                  trackBtn("copyLink");
                  let params = {
                    bass: localStorage.getItem("bass"),
                    horns: localStorage.getItem("horns"),
                    percussion: localStorage.getItem("percussion"),
                    piano: localStorage.getItem("piano"),
                    sfx: localStorage.getItem("sfx"),
                    vox: localStorage.getItem("vox"),
                  };

                  const url = new URL(window.location.href);

                  for (const [key, value] of Object.entries(params)) {
                    url.searchParams.append(key, value);
                  }

                  alert("Link copied to clipboard");

                  copyToClipboard(
                    url.origin + "/center-stage/broad" + url.search
                  );
                }}
              />

              <div className="block" style={{ height: "5%" }} />

              <div
                style={{
                  display: "flex",
                  width: "70%",
                  height: "12%",
                  justifyContent: "space-between",
                  margin: "0 auto",
                  position: "relative",
                }}
              >
                <div
                  className="imgContaienr"
                  onClick={shareUrl}
                  style={{
                    width: "33.333%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/2d/facebook.webp" style={{ height: "100%" }} />
                </div>
                <div
                  className="imgContaienr"
                  onClick={shareUrl}
                  style={{
                    width: "33.333%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/2d/x.webp" style={{ height: "100%" }} />
                </div>
                <div
                  className="imgContaienr"
                  onClick={shareUrl}
                  style={{
                    width: "33.333%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/2d/mail.webp" style={{ height: "100%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
// share url
async function shareUrl() {
  let params = {
    bass: localStorage.getItem("bass"),
    horns: localStorage.getItem("horns"),
    percussion: localStorage.getItem("percussion"),
    piano: localStorage.getItem("piano"),
    sfx: localStorage.getItem("sfx"),
    vox: localStorage.getItem("vox"),
  };

  const url = new URL(window.location.href);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  let data = {
    title: "Ralph’s Club Mix",
    text: "Step into the realm of Ralph's Club Singapore, where magic and elegance intertwine.",
    url: url,
  };

  try {
    await navigator.share(data);
  } catch (err) {
    console.log(err);
  }
}

// bass , horns , percussion , piano , sfx , vox
function copyToClipboard(text) {
  if (typeof navigator !== "undefined" && navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  } else {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
}
