import { motion, AnimatePresence } from "framer-motion";
import { useMemo, useState } from "react";
import "../../app.scss";
import NavButton from "../../assets/navButton.svg";
import NavExit from "../../assets/navExit.svg";
import Volumn from "../../assets/volumn.svg";
import { useLocation, useHistory } from "react-router-dom";
import RalphLogo from "../../assets/ralphLogo.svg";
import { useNavigate, Link } from "react-router-dom";
import { useStore } from "../../store";
import SvgComponent from "../transition";
import { Wrap } from "../helper";
const NavBar = ({ phaserRef }) => {
  const [open, setOpen] = useState(true);
  const [soundOn, setSoundOn] = useState(true);
  const { isSoundOn, isReady } = useStore((state) => state);
  const actions = useStore((state) => state.actions);
  const [triggerSvg, setTriggerSvg] = useState(true);

  const { setIsSoundOn } = actions;

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
    setSoundOn(!soundOn);
  };

  const item = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 2.5,
        delay: 2,
      },
    },
  };

  const pedding = "1rem";

  const menu = [
    "Welcome Entrance",
    "Elevator",
    "Olfactive Bar",
    "Fashion Walkway",
    "center stage",
    "VIP lounge",
  ];
  return (
    <div
      style={{
        height: "100svh",
        position: "fixed",
        top: "0",
      }}
    >
      <motion.div
        style={{
          backgroundImage: "url('/2d/bg.webp')",
          opacity: open ? 1 : 0,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100vw",
          height: "100svh",
          zIndex: "100",
          position: "absolute",
          zIndex: "100",
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
      >
        <AnimatePresence>
          {isReady && open && (
            <motion.div
              className="menu_container"
              variants={item}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100vh", opacity: 1 }}
              transition={{ ease: [0.4, 0, 0.2, 1], duration: 2 }}
              exit="exit"
              style={{
                zIndex: "100",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: `0 ${pedding}`,
                  height: "fit-content",
                  alignItems: "center",
                  top: "2svh",
                }}
              >
                <div
                  style={{
                    opacity: "0",
                  }}
                >
                  <img src={NavExit} alt="exitLogo" width={55} />
                </div>
                {/* <motion.h2 className="mb-16 leading-4">RALPH'S CLUB</motion.h2> */}
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <img
                    src={"/2d/logo3.webp"}
                    alt="navbutton"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  style={{
                    opacity: "0",
                  }}
                >
                  <img src={NavExit} alt="exitLogo" width={55} />
                </div>
              </div>
              <div
                style={{
                  paddingTop: "0svh",
                }}
              >
                {menu.map((param, index) => {
                  return (
                    <Diamond
                      param={param}
                      index={index}
                      key={param + index}
                      setOpen={setOpen}
                      phaserRef={phaserRef}
                      setTriggerSvg={setTriggerSvg}
                    />
                  );
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const Diamond = ({
  marginTop = "-5.5svh",
  param,
  index,
  phaserRef,
  setTriggerSvg,
  setOpen,
}) => {
  const navigate = useNavigate();

  const location = useLocation();
  const [ishover, setIsHover] = useState(false);

  const route = useMemo(() => {
    switch (param) {
      case "Welcome Entrance":
        return "welcome";
      case "Elevator":
        return "elevator";
      case "Olfactive Bar":
        return "olfactive-bar";
      case "Fashion Walkway":
        return "fashion-walkway";
      case "center stage":
        return "center-stage";
      case "VIP lounge":
        return "vip";
      default:
        return "";
    }
  }, [param]);

  const getStrokeColor = () => {
    return location.pathname === "/" + route ? "#FFF" : "#373737";
  };

  const storeColor = useMemo(() => {
    let color = getStrokeColor(route);
    return color;
  }, [location.pathname]);

  return (
    <motion.div
      key={"diamond" + index}
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: !ishover ? 2.3 : "0.5",
        delay: 0.6 * (4 - index),
      }}
      exit={{
        opacity: 0,
        y: "-2svh",
        transition: {
          duration: 1,
          delay: 0.2 * (4 - index),
        },
      }}
      style={{
        position: "relative",
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 4 - index + 1,
        marginTop: marginTop,
      }}
    >
      <Polygon stroke={storeColor} index={index} key={"diamond" + index} />
      <span
        style={{
          position: "absolute",
          color: "#adadad",
          fontSize: "1rem",
          letterSpacing: "0.23em",
          textTransform: "uppercase",
          fontFamily: " Sackers,Libre Franklin,ui-sans-serif,system-ui",
          zIndex: "5",
        }}
      >
        <h4
          className="hover:text-white"
          style={{
            textAlign: "center",
          }}
          onClick={() => {
            // can't directly route to the page i want
            if (route === "center-stage") {
              phaserRef.current.scene.scenes[0].toScene("djBroadPre");
              phaserRef.current.scene.scenes[3].toScene("djBroadPre");
              phaserRef.current.scene.scenes[4].toScene("djBroadPre");
              phaserRef.current.scene.scenes[5].toScene("djBroadPre");
              phaserRef.current.scene.scenes[6].toScene("djBroadPre");
            }

            if (route === "fashion-walkway") {
              phaserRef.current.scene.scenes[0].toScene("video");
              phaserRef.current.scene.scenes[3].toScene("video");
              phaserRef.current.scene.scenes[4].toScene("video");
              phaserRef.current.scene.scenes[5].toScene("video");
              phaserRef.current.scene.scenes[6].toScene("video");
            }

            if (route === "welcome") {
              phaserRef.current.scene.scenes[0].toScene("door");
              phaserRef.current.scene.scenes[3].toScene("door");
              phaserRef.current.scene.scenes[4].toScene("door");
              phaserRef.current.scene.scenes[5].toScene("door");
              phaserRef.current.scene.scenes[6].toScene("door");
            }

            if (route === "elevator") {
              phaserRef.current.scene.scenes[0].toScene("video2");
              phaserRef.current.scene.scenes[3].toScene("video2");
              phaserRef.current.scene.scenes[4].toScene("video2");
              phaserRef.current.scene.scenes[5].toScene("video2");
              phaserRef.current.scene.scenes[6].toScene("video2");
            }

            setOpen(false);
            setTimeout(() => {
              navigate(`/${route}`);
            }, 1500);
          }}
        >
          {param}
        </h4>
      </span>
    </motion.div>
  );
};

const Polygon = ({ stroke, index }) => {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 399.28 176.9"
      style={{
        height: 17.5 + "svh",
        width: 17.5 * 2 + "svh",
        zIndex: "5",
      }}
    >
      <defs>
        <style>
          {`.cls-1{fill:none;stroke-miterlimit:10;stroke-width:7px;}`}
        </style>
      </defs>
      <g id="Layer_2-2">
        <g id="Layer_1-2">
          <polygon
            // change the stroke color
            stroke={stroke}
            className="cls-1"
            points="198.46 173.07 8.6 88.44 198.46 3.83 390.6 88.44 198.46 173.07"
          />
        </g>
      </g>
    </svg>
  );
};

export default NavBar;
