import { useNavigate } from "react-router-dom";
import { Wrap, Button, MotionWrap } from "../helper";
import { motion } from "framer-motion";
import { useState } from "react";
import SvgComponent, { InvertSvg } from "../transition";

const Walkway = ({ phaserRef, routeTo = "/" }) => {
  const nav = useNavigate();
  return (
    <div
      onClick={() => {
        phaserRef.current.scene.scenes[4].video.play();
      }}
    >
      <Wrap>
        <div
          className="bg"
          style={{
            width: "100cvw",
            height: "66svh",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10svh",
              background:
                "linear-gradient(0deg, rgba(0,0,0,1) 39%, rgba(0,0,0,0) 100%)",
              position: "relative",
              position: "absolute",
              bottom: "0%",
            }}
          />
        </div>

        <div className="block" style={{ height: "4svh" }} />

        <h2
          style={{
            textAlign: "center",
          }}
        >
          RALPH’S CLUB <br />
          INSPIRATION
        </h2>
        <div className="block" style={{ height: "4svh" }} />

        <p
          style={{
            textAlign: "center",
          }}
        >
          Inspired by Ralph Lauren’s one-night-only fall 2019
          <br /> fashion experience, Ralph’s Club represents the <br />
          best night of your life captured in a fragrance.
        </p>
      </Wrap>
    </div>
  );
};

export default Walkway;
